<template>
	<div>
		<div class="bigbox" v-if="list && list.length">
			<div class="serverInfo mb-20" v-for="(item, index) in list" :key="index">
				<div class="header flex">
					<div class="item col1 colText">
						<span class="fontColor">下单时间:{{ item.goodsOrder.create_time }}</span>
						<span class="darkColor">订单编号：{{ item.goodsOrder.orderNo }}</span>
						<!-- <img src="../../img/talking.png"/> -->
						<!-- <span class="blueColor">和我联系</span> -->
						<span class="blueColor fs-18" style="float: right;margin-right: 130px;">{{ item.goodsOrder.orderStatus | handleorderStatus }}</span>
					</div>
					<!-- <div class="col4 flex jc-center ai-center cursor" v-if="['15', '20', '99'].includes(activeName)"
						@click="delet(item.goodsOrder.id)">
						删除
					</div> -->
				</div>
				<div class="list flex" v-for="(its, q) in item.goodsOrderSku" :key="'sku' + q">
					<div class="item flex col1">
						<div class="flex ai-center p-10">
							<div class="listImg"><img :src="photoURL + its.image" /></div>
							<div>
								<div class="brandTop words1">{{ its.goodsName }}</div>
								<div class="brandTop darkColor">{{ '型号：' + its.number }}</div>
								<div class=" darkColor">{{ '品牌：' + its.brandName }}</div>
							</div>
						</div>
					</div>
					<div class="item col2">
						<!-- 订单数量 -->
						<span>{{ '数量：' + its.quantity }}</span>
					</div>
					<div class="item col3 flex flex-col jc-center ai-center">
						<span class="fs-16 blueColor">{{ its.price == 0 ? '洽谈' : '¥' + its.price }}</span>
						<span class="pt-5" v-if="['1', '0','10','20','30','40'].includes(activeName)">转账汇款</span>
					</div>

					<!-- 状态1 待确认 20待发货  -->
					<div class="item col4 flex jc-center ai-center" v-if="item.goodsOrder.orderStatus == '1' || item.goodsOrder.orderStatus == '20'">
						<div>
							<div class="cursor" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</div>
							<div class="cursor" @click="cancelOrder(item.goodsOrder.id)">取消订单</div>
						</div>
					</div>
					<!-- 状态30 待收货  -->
					<div class="item col4 flex jc-center ai-center" v-if="item.goodsOrder.orderStatus == '30'">
						<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
							<el-button type="primary" @click="Receive(item.goodsOrder.id)">确认收货</el-button>
							<!-- <div class="cursor" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看物流</div> -->
							<div class="cursor" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</div>
						</div>
					</div>
					<!-- 状态10 待付款 -->
					<div class="item col4 flex flex-col jc-center ai-center" v-if="item.goodsOrder.orderStatus == '10'">
						<el-button type="primary" @click="uploadCredentials(item)">上传支付凭证</el-button>
						<span class="cursor pt-10" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</span>
						<span class="cursor pt-10" @click="cancelOrder(item.goodsOrder.id)">取消订单</span>
					</div>
					<!-- 状态99 已取消 -->
					<div class="item col4 flex jc-center ai-center" v-if="item.goodsOrder.orderStatus == '99'">
						<div class="flex flex-col jc-center ai-center"><span class="cursor pt-10" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</span></div>
					</div>
					<!-- 状态40 已完成 -->
					<div class="item col4 flex jc-center ai-center" v-if="item.goodsOrder.orderStatus == '40'">
						<div class="flex flex-col jc-center ai-center"><span class="cursor pt-10" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</span></div>
					</div>
					<!-- 状态15 待评价 -->
					<!-- <div class="item col4 flex jc-center ai-center" v-if="item.goodsOrder.orderStatus == 15">
						<div class="flex flex-col jc-center ai-center"><span class="cursor pt-10" @click="toDetails(item.goodsOrder.orderStatus, item.goodsOrder.id)">查看详情</span></div>
					</div> -->
				</div>
			</div>
			<!-- 弹窗 -->
			<cancelorderDia ref="cancelorderDia" :orderId="Id" @cancelSubimt1="cancelSubimt1"></cancelorderDia>
			<!-- 上传转账凭证弹窗 -->
			<el-dialog title="上传支付凭证" :visible.sync="credentialsDia" width="20%">
				<div class="flex">
					<!-- 上传组件 -->
					<el-upload
						class="avatar-uploader mr-10"
						:action="baseURL + '/base-api/file/upload'"
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:on-change="handleChange"
					>
						<img v-if="submitForm.path" :src=" photoURL+submitForm.path" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="credentialsDia = false">取 消</el-button>
					<el-button type="primary" @click="submit">提交审核</el-button>
				</span>
			</el-dialog>
			<!-- 分页 -->
			<div class="globalpPagination">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="current"
					:page-size="size"
					:total="total"
				></el-pagination>
			</div>
			
		</div>
		<div class="empty flex jc-center ai-center" v-else>
			<div class="flex flex-col jc-center ai-center">
				<img src="../../../img/statusEmpty.png" />
				<span>暂无相关内容</span>
			</div>
		</div>
		
	</div>
</template>

<script>
import cancelorderDia from '../../../component/common/cancelorderDia-shop';
export default {
	data() {
		return {
			Id: '',
			submitForm: {
				path: '', //转账凭证 图片
				id: ''
			},
			bankList: [],
			fileList: [],
			credentialsDia: false,
			acceptance: false, //填写转账凭证弹出层
			total: 10,
			current: 1,
			size: 5,
			list: [],
		};
	},
	props: {
		activeName: {
			type: String,
			required: true
		}
	},
	watch: {
		activeName(newVal) {
			this.getList(newVal);
		}
	},
	methods: {
		cancelSubimt1() {
			this.getList('0');
		},
		getList(orderStatus) {
			const form = {
				current: this.current,
				size: this.size,
				orderStatus: +orderStatus
			};
			if (orderStatus == 0) {
				form.orderStatus = '';
			}
			this.$get('/shop-api/mall/goods-order/list', form).then(data => {
				this.list = data.data.records;
				this.total = data.data.total;
			});
		},
		handleSizeChange(val) {
			this.size = val;
			this.getList(this.activeName);
		},
		handleCurrentChange(val) {
			this.current = val;
			this.getList(this.activeName);
		},
		// 取消订单弹框
		cancelOrder(id) {
			this.$refs.cancelorderDia.cancelOrderDiaVisible = true;
			this.Id = id;
		},
		// 删除
		delet(id) {
			this.$post('/shop-api/mall/expert-order/delete', {
				id
			}).then(data => {
				if (data.code == 1000) {
					this.$message.success('删除成功');
					this.getList();
				} else {
					this.$message.info(data.msg);
				}
			});
		},
		handleAvatarSuccess(file) {
			this.submitForm.path = file.data;
		},
		handleChange(file) {
			this.fileList.push(file.uid);
		},
		// 上传凭证弹窗
		uploadCredentials(item) {
			this.submitForm.id = item.goodsOrder.id;
			this.credentialsDia = true;
		},
		// 提交审核
		submit() {
			this.$postJson('/shop-api/mall/goods-order/pay', this.submitForm).then(data => {
				if (data.code != 1000) {
					this.$message.info(data.msg);
					return;
				}
				this.$message.success('提交成功');
				this.credentialsDia = false;
				this.submitForm = {};
				this.getList(this.activeName);
			});
		},
		//收货
		Receive(id) {
			this.$confirm('确定收货?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$postJson('/shop-api/mall/goods-order/receive', { id: id }).then(data => {
						if (data.code != 1000) {
							this.$message.info(data.msg);
							return;
						}
						this.$message.success('收货成功');
						this.getInfo();
					});
				})
				.catch(() => {});
		},
		toDetails(status, id) {
			this.$router.push({
				path: '/orderDetail-shop',
				query: {
					id
				}
			});
		}
	},
	filters: {
		//   处理订单状态
		handleorderStatus: function(val) {
			switch (val) {
				case 1:
					return '待确认';
				case 10:
					return '待付款';
				case 20:
					return '待发货';
				case 30:
					return '待收货';
				case 40:
					return '已完成';
				case 99:
					return '已取消';
			}
		}
	},
	components: {
		cancelorderDia
	},
	mounted() {
		this.getList('0');
	}
};
</script>
<style lang="scss" scoped>
* {
	.bigbox {
		width: 98%;
		margin: 0 auto;

		.serverInfo {
			.col1 {
				flex: 1;

				img {
					width: 16px;
					height: 16px;
				}

				span:nth-child(2) {
					padding-left: 15px;
				}

				span:nth-child(3) {
					padding-left: 300px;
				}
			}

			.col2 {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 104px;

				div {
					height: 2px;
				}
			}

			.col3 {
				width: 120px;
				text-align: center;

				div {
					height: 20px;
				}
			}

			.col4 {
				width: 320px;

				div {
					height: 20px;
				}
			}

			.colText {
				text-align: left !important;
				padding-left: 15px;
			}

			.header {
				border: 1px solid #bae7ff;
				background-color: #e6f7ff;
				display: flex;
				color: #595959;

				.item {
					text-align: center;
					line-height: 54px;
					height: 54px;
				}
			}

			.list {
				display: flex;

				.item {
					height: 148px;
					border-right: 1px solid #dddddd;
					border-bottom: 1px solid #dddddd;

					&:first-child {
						border-left: 1px solid #dddddd;
					}

					.listImg {
						width: 100px;
						height: 100px;
						margin-right: 15px;

						img {
							border: 1px solid #dddddd;
							border-radius: 10px;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.brandTop {
						height: 30px;
						text-align: left;
						line-height: 30px;
					}
				}
			}
		}

		.empty {
			height: 700px;
		}
	}

	.empty {
		color: #8c8c8c;
		min-height: 700px;
	}

	.words1 {
		font-size: 16px;
		font-weight: 600;
		padding-right: 20px;
		color: #262626;
	}

	.top {
		margin-bottom: 22px;

		ul {
			li {
				margin-top: 10px;
				list-style: none;
			}
		}
	}
}
.download {
	padding: 10px 20px;
	color: #409eff;
	cursor: pointer;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}

::v-deep .el-dialog .el-dialog__header {
	background-color: #f8f8f8;
}

::v-deep .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 88px;
	height: 88px;
	line-height: 88px;
	text-align: center;
}

.avatar {
	width: 88px;
	height: 88px;
	display: block;
}

.el-select {
	margin-bottom: 10px;
	width: 100%;
}
.cursor {
	cursor: pointer;
}
.cursor :hover {
	color: #409eff;
}
</style>
